import { post } from '@/utils/request';

// 调拨统计-获取调拨统计默认或设置项
const getStatistical = (data) => post({
    url: '/store/statistical.allot/getStatistical',
    data: data
});

// 调拨统计-编辑调拨统计表
const editStatistical = (data) => post({
    url: '/store/statistical.allot/editStatistical',
    data: data
})


// 调拨统计-新增调拨统计表
const addStatistical = (data) => post({
    url: '/store/statistical.allot/addStatistical',
    data: data
})

// 调拨统计-获取调拨统计表列表
const getStatisticalList = (data) => post({
    url: '/store/statistical.allot/getStatisticalList',
    data: data
})

// 调拨统计-获取统计维度数据
const getTotalStatisticalData = (data) => post({
    url: '/store/statistical.allot/totalStatisticalData',
    data: data
})

// 调拨统计-获取雷达图数据
const getTotalStatisticalRadar = (data) => post({
    url: '/store/statistical.allot/totalStatisticalRadar',
    data: data
})

// 调拨统计-时间轴数据
const getTotalStatisticalTime = (data) => post({
    url: '/store/statistical.allot/totalStatisticalTime',
    data: data
})

// 明细
const getTotalStatisticalList = (data) => post({
    url: '/store/statistical.allot/totalStatisticalList',
    data: data
})

const getAttrValueList = (data) => post({
    url: '/store/common.select/getAttrValueList',
    data: data
})

const getMaterialListReq = () => post({
    url: '/store/common.Select/getMaterialList',
});


// 导出
const downloadList = (data) => post({
    url: '/store/statistical.allot/downloadList',
    responseType: 'blob',
    data: data
})

// 删除
const deleteStatistical = (data) => post({
    url: '/store/statistical.allot/deleteStatistical',
    data: data
})

// 仓库
const getWarehouseListAll = (data) => post({
    url: '/store/goods.EntryCtl/getWarehouseListAll',
    data: data
})



export {
    getStatistical,
    editStatistical,
    addStatistical,
    getStatisticalList,
    getTotalStatisticalData,
    getTotalStatisticalRadar,
    getTotalStatisticalTime,
    getTotalStatisticalList,
    getAttrValueList,
    downloadList,
    getMaterialListReq,
    deleteStatistical,
    getWarehouseListAll
}