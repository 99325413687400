var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handlePrint}},[_vm._v("打印")]),_c('el-dialog',{attrs:{"title":"打印预览（A4纸）","visible":_vm.dialogVisible,"before-close":_vm.handleClose,"width":"80%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"paper",staticStyle:{"font-size":"12px","font-family":"'宋体'","width":"21cm"},attrs:{"id":"paper"}},[_c('div',{staticClass:"content",staticStyle:{"width":"100%","position":"relative","background":"white"}},[_c('div',{staticClass:"title",staticStyle:{"text-align":"center","line-height":"40px","font-size":"20px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.day_time !='')?_c('div',[_vm._v(" 统计时间："+_vm._s(_vm.parseTime(_vm.day_time[0], "{y}-{m}-{d}"))+" 至 "+_vm._s(_vm.parseTime(_vm.day_time[1], "{y}-{m}-{d}"))+" 打印时间："+_vm._s(_vm.parseTime(new Date(), "{y}-{m}-{d} {h}:{i}:{s}"))+" ")]):_vm._e(),_vm._l((_vm.printData.top),function(item,index){return _c('div',{key:index,staticClass:"txt",staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((item),function(_,_index){return _c('div',{key:_index,staticClass:"item",style:({
                  width: _.width,
                  display: 'flex',
                  marginRight: '1%',
                  marginTop: '20px',
                })},[_c('div',[_vm._v(_vm._s(_.txt)+"：")]),_c('div',{staticClass:"border",staticStyle:{"flex":"1","border-bottom":"1px solid black","word-break":"break-all","position":"relative"}},[_vm._v(" "+_vm._s(_.value)+" ")])])}),0)}),(_vm.tableType === 1)?_c('table',{staticClass:"my-table",staticStyle:{"border-left":"1px solid black","margin":"0 auto","margin-top":"20px"}},[_c('tr',{staticClass:"header",staticStyle:{"display":"flex"}},[_vm._l((_vm.printRows),function(item){return [_c('th',{key:item.field_alias,staticClass:"cell",style:({
                      background: '#EAEDED',
                      borderTop: '1px solid black',
                      width: item.width?(item.width / 10) + 'cm':(item.t_width / 100) + 'cm',
                      minHeight: '0.6cm',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      borderRight: '1px solid black',
                      borderBottom: '1px solid black',
                      fontWeight: 'bold',
                    })},[_vm._v(" "+_vm._s(item.field_text)+" ")])]})],2),_vm._l((_vm.printData.list),function(item,index){return _c('tr',{key:index,staticClass:"row",staticStyle:{"display":"flex"}},_vm._l((_vm.printRows),function(cell){return _c('td',{key:cell.field_alias,staticClass:"cell",style:({
                    width:cell.width?(cell.width / 10) + 'cm':(cell.t_width / 100) + 'cm',
                    minHeight: '0.6cm',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') || (cell.field_alias === 'remark') || (cell.field_alias === 'pay_channel_name')? 'flex-start' : cell.field_alias === 'index' ? 'center' : 'flex-end',
                    borderRight: '1px solid black',
                    borderBottom: '1px solid black',
                    wordBreak: 'break-all',
                    fontWeight: item.index === '合计' ? 'bold' : '',
                    flexDirection: ((item.info && cell.field_alias !== 'index') || (cell.is_goods && index !== _vm.printData.list.length - 1)) ? 'column': '',
                  })},[(item.info && cell.field_alias !== 'index')?_vm._l((item.info),function(info,infoIndex){return _c('div',{key:infoIndex,style:({
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') ? 'flex-start' : cell.field_alias === 'edit_sign' ? 'center' : 'flex-end',
                          minHeight: '0.6cm',
                          borderBottom: infoIndex !== 2 ? '1px solid black': '',
                          color: (infoIndex%2 != 0 && info[cell.field_alias] != item.info[infoIndex - 1][cell.field_alias]) ? '#F56C6C' : '',
                        })},[_c('div',[(item.index !== '合计')?[(info.edit_sign !== '差异')?_c('span',[_vm._v(" "+_vm._s(info[cell.field_alias] ? info[cell.field_alias] : '--')+" ")]):_c('span',[_vm._v(" "+_vm._s(info[cell.field_alias] || info[cell.field_alias] === 0 ? info[cell.field_alias] : '')+" ")])]:[_vm._v(" "+_vm._s(info[cell.field_alias] || info[cell.field_alias] === 0 ? info[cell.field_alias] : '')+" ")]],2)])}):(cell.is_goods)?_vm._l((item.goods),function(info,infoIndex){return _c('td',{key:infoIndex,style:({
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') ? 'flex-start' : cell.field_alias === 'edit_sign' ? 'center' : 'flex-end',
                          minHeight: '0.6cm',
                          borderBottom: infoIndex === item.goods.length - 1 ? '': '1px solid black',
                          wordWrap:'break-word'
                        })},[_vm._v(" "+_vm._s(info[cell.field_alias] ? info[cell.field_alias] : '--')+" ")])}):[_c('div',[_vm._v(" "+_vm._s(item[cell.field_alias])+" ")])]],2)}),0)})],2):_c('table',{staticStyle:{"margin-top":"20px","border":"1px solid black"},attrs:{"cellspacing":"0"}},[_c('tr',{staticStyle:{"background":"#EAEDED","font-weight":"bold"}},[_vm._l((_vm.printRows),function(item,index){return [_c('th',{key:item.field_alias,style:({
                      borderBottom: '1px solid black',
                      borderRight: index === _vm.printRows.length - 1 ? '' : '1px solid black',
                    })},[_vm._v(" "+_vm._s(item.field_text)+" ")])]})],2),_vm._l((_vm.printData.list),function(item,index){return _c('tr',{key:index},_vm._l((_vm.printRows),function(cell,cellIndex){return (cell.is_goods || item.rowspan || index === _vm.printData.list.length - 1)?_c('td',{key:cell.field_alias,style:({
                    width: cell.width / 10 + 'cm',
                    borderBottom: index === _vm.printData.list.length - 1 ? '' : '1px solid black',
                    borderRight: cellIndex === _vm.printRows.length - 1 ? '' : '1px solid black',
                    minHeight: '0.6cm',
                    wordBreak: 'break-all',
                    fontWeight: index === _vm.printData.list.length - 1 ? 'bold' : '',
                    textAlign: (cell.field_alias === 'index') || (cell.field_alias === 'create_time') ? 'center' : (cell.field_alias === 'goods_number') || (cell.field_alias === 'goods_name') ? 'left' : 'right'
                  }),attrs:{"rowspan":!cell.is_goods ? item.rowspan : ''}},[[_vm._v(" "+_vm._s(item[cell.field_alias])+" ")]],2):_vm._e()}),0)})],2),_vm._l((_vm.printData.bottom),function(item,index){return _c('div',{key:'txt' + index,staticClass:"txt",staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((item),function(_,_index){return _c('div',{key:_index,staticClass:"item",style:({
                  width: _.width,
                  display: 'flex',
                  marginRight: '1%',
                  marginTop: '20px',
                })},[_c('div',[_vm._v(_vm._s(_.txt)+"：")]),_c('div',{staticClass:"border",staticStyle:{"position":"relative","flex":"1","border-bottom":"1px solid black","word-break":"break-all"}},[_vm._v(" "+_vm._s(_.value)+" ")])])}),0)})],2)])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),_c('el-button',{directives:[{name:"print",rawName:"v-print",value:(_vm.printObj),expression:"printObj"}],attrs:{"type":"primary"}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }