import { post } from '@/utils/request';

// 智能助手列表
const noticeList = (data) => post({
    url: '/store/app.StoreNotice/list',
    data: data
});

// 门店选择
const getMerchantList = (data) => post({
    url: '/store/app.Home/getMerchantList',
    data: data
})

// 门店选择
const getHierarchyAll = (data) => post({
    url: '/store/hierarchy.HierarchySetCtl/getHierarchyAll',
    data: data
})

// 数据简报
const brief = (data) => post({
    url: "/store/statistical.Home/brief",
    data: data
})

// 销售品类占比
const hotType = (data) => post({
    url: "/store/statistical.Home/hotType",
    data: data
})

// 销售业绩趋势
const sale_trend = (data) => post({
    url: "/store/statistical.Home/sale_trend",
    data: data
})

export {
    noticeList,
    getMerchantList,
    brief,
    hotType,
    sale_trend,
    getHierarchyAll
}
